import React from 'react';
import { WrithingText } from '../writhingText/writhingText'


const WelcomeBanner = () => {
    return(
        <div className='welcomeBannerBlob'>
            <WrithingText value='site under construction...'/>
        </div>
    );
}

export default WelcomeBanner;