import React from 'react';
import './App.css';
import { defaultSpookyState, Spooky } from "./components/spookyGhost/spooky";
import WelcomeBanner from './components/welcomeBanner/welcomeBanner';

function App() {
  return (
    <>
      <Spooky {...defaultSpookyState} />
      <WelcomeBanner />
    </>
  );
}

export default App;
