import { TransitionAnimation } from "../transitionAnimation"


const IdleAnimation: TransitionAnimation = {
    classNames: "idle",
    timeout: 1500,
    appear: true
}

export { IdleAnimation }
