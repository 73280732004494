import { TransitionAnimation } from "../transitionAnimation"


const WanderAnimation: TransitionAnimation = {
    classNames: "wanderIn",
    timeout: 1500,
    appear: true,
    finalX: '35vw',
    finalY: '50vh'
}

export { WanderAnimation }
