import React, { useState } from 'react';
import './writhingText.css';

interface textProps {
    value: string;
}

const WrithingText: React.FC<textProps> = (props: textProps) => {
    return (
        <>
            <div className='writhing'>
                {props.value}
            </div>
        </>
    );
};


export { WrithingText };