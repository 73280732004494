import { makeStyles } from "@material-ui/core";
import { createRef, useEffect, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { IdleAnimation } from "./AnimationWrapper/Animations/IdleAnimation";
import { WanderAnimation } from "./AnimationWrapper/Animations/WanderAnimation";
import "./AnimationWrapper/ghostAnimations.css";
import { TransitionAnimation } from "./AnimationWrapper/transitionAnimation";
import ghost from './ghosty.svg';
import "./spooky.css";

// Define state props
interface SpookyStateProps {
    posX: string,
    posY: string,
    animationState: TransitionAnimation,
    speechBubbleActive: boolean,
    bubbleText: string
}

// Default props.
export const defaultSpookyState: SpookyStateProps = {
    posX: "-25vw",
    posY: WanderAnimation.finalY?? "0vh",
    animationState: WanderAnimation,
    speechBubbleActive: false,
    bubbleText: ""
}

// Get a stateful ref to the props default controller state.
// When a TransitionAnimation occurs, use the exposed hooks to update the CSS values
// in MUI's styles.
const Spooky: React.FC<SpookyStateProps> = (props: SpookyStateProps) => {
    const [controllerState, setControllerState] = useState<SpookyStateProps>(props);
    const [inProp, setInProp] = useState<boolean>(false);
    const containerRef = createRef<HTMLDivElement>();
    const [currentAnimation, setAnimation] = useState<TransitionAnimation>(WanderAnimation);
    const [animationQueue, setAnimationQueue] = useState<Array<TransitionAnimation>>([])

    const styles = useStyles(controllerState);
    useEffect(() => {
        setAnimation(WanderAnimation);
        setInProp(true);
        // containerRef?.current?.style.setProperty("display", "inline")
        animationQueue.push(IdleAnimation);
    }, [])

    const updateController = () => {
        console.log("changing!")
        console.log(animationQueue)
        if (animationQueue.length > 0) {
            setAnimation(animationQueue.pop() ?? IdleAnimation);
            let cX = controllerState.posX;
            let cY = controllerState.posY;
            setControllerState({ ...controllerState, posX: currentAnimation.finalX ?? cX, posY: currentAnimation.finalY ?? cY })
        }
    }

    return (
        <SwitchTransition>
            <CSSTransition in={inProp}
                timeout={currentAnimation.timeout}
                classNames={currentAnimation.classNames}
                key={currentAnimation.classNames + '2'}
                appear={true}
                onEntered={updateController}
            >
                <div ref={containerRef} className={styles.ghostHolder}>
                    <img className={`${styles.controllerStyles} ${styles.spooky}`} src={ghost} alt="" draggable={false} />
                </div>
            </CSSTransition>
        </SwitchTransition>
    );
};

const useStyles = makeStyles({
    controllerStyles: (props: SpookyStateProps) => ({
        position: "absolute",
        transform: `translate(${props.posX}, ${props.posY})`
    }),
    spooky: {
        background: "transparent",
        overflow: 'hidden'
    },
    ghostHolder: {
        width: "fit-content"
    }
});

export { Spooky };

